import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from '@remix-run/react';
import { uuid4 } from '@sentry/utils';
import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select, { type SingleValue } from 'react-select';
import useSWR, { type KeyedMutator } from 'swr';

import {
  type DtoAIModel,
  type DtoPromptTemplate,
  EnumsAIVendor,
  EnumsPromptTemplateType,
  type ModelsFunctionDefinition,
  type ModelsPromptTemplateSettings,
  type ModelsVariableDefinition,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { DeleteIcon } from '../icons/DeleteIcon';
import { Loading } from '../Loading';
import { PromptTemplateUtils } from './utils';

const typeOptions: Option<EnumsPromptTemplateType>[] = [
  {
    value: EnumsPromptTemplateType.PromptTemplateTypeSimple,
    label: EnumsPromptTemplateType.PromptTemplateTypeSimple,
  },
  {
    value: EnumsPromptTemplateType.PromptTemplateTypeAssistant,
    label: EnumsPromptTemplateType.PromptTemplateTypeAssistant,
  },
];

const vendorOptions: Option<EnumsAIVendor>[] = [
  {
    value: EnumsAIVendor.AIVendorOpenai,
    label: EnumsAIVendor.AIVendorOpenai,
  },
];

type FunctionDefinition = Omit<ModelsFunctionDefinition, 'parameters'> & {
  parameters: string;
};

type FormData = {
  name: string;
  vendor: EnumsAIVendor;
  vendorModelId: string;
  type: EnumsPromptTemplateType;
  systemPrompt: string;
  functions: FunctionDefinition[];
  variables: ModelsVariableDefinition[];
  settings: ModelsPromptTemplateSettings;
};

function safePrettyParameters(parameters: string | undefined) {
  if (!parameters) return '';
  try {
    return JSON.stringify(JSON.parse(parameters), null, 2);
  } catch (error) {
    return parameters;
  }
}

function FunctionEditor(props: {
  functions: FunctionDefinition[];
  initial: FunctionDefinition | null;
  onCancel: () => void;
  onSave: (fn: FunctionDefinition) => void;
  editing: boolean;
}) {
  const { functions } = props;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FunctionDefinition>({
    defaultValues: {
      ...props.initial,
      id: props.initial?.id ?? uuid4(),
      parameters: safePrettyParameters(props.initial?.parameters),
    },
  });

  function submit(data: FunctionDefinition) {
    if (!props.editing) return;
    if (functions.some((fn) => fn.id !== data.id && fn.name === data.name)) {
      setError('name', {
        type: 'custom',
        message: 'Function names must be unique.',
      });
      return;
    }
    if (
      data.required &&
      functions.some((fn) => fn.id !== data.id && fn.required)
    ) {
      setError('required', {
        type: 'custom',
        message: 'Only one function can turn on Force Call.',
      });
      return;
    }
    try {
      const json = JSON.parse(data.parameters);
      if (!json || typeof json !== 'object' || Array.isArray(json)) {
        throw new Error('Invalid JSON Object');
      }
      data.parameters = JSON.stringify(json);
    } catch (error) {
      setError('parameters', {
        type: 'custom',
        message: 'The parameters is not a valid JSON Object.',
      });
      return;
    }
    props.onSave(data);
  }

  return (
    <form
      className='w-full flex flex-col gap-4 text-white'
      onSubmit={handleSubmit((data) => {
        submit(data);
      })}
    >
      <div className='text-xl text-center'>{`${
        props.editing ? (!props.initial ? 'Add' : 'Update') : 'View'
      } Function Definition`}</div>
      <div>
        <p className='text-sms mb-1 font-bold'>Name:</p>
        <input
          {...register('name', { required: true })}
          className={`${errors.name ? 'field-error' : 'field'} mb-0`}
          disabled={!props.editing}
        />
        <ErrorMessage
          errors={errors}
          name='name'
          render={({ message }) => (
            <div className='text-sms text-red-002'>{message}</div>
          )}
        />
      </div>
      <div>
        <p className='text-sms mb-1 font-bold'>Description:</p>
        <input
          {...register('description', { required: true })}
          className={`${errors.description ? 'field-error' : 'field'} mb-0`}
          disabled={!props.editing}
        />
        <ErrorMessage
          errors={errors}
          name='description'
          render={({ message }) => (
            <div className='text-sms text-red-002'>{message}</div>
          )}
        />
      </div>
      <div className='flex items-center gap-4'>
        <div className='flex items-center gap-4'>
          <p className='text-sms font-bold'>
            <a
              href='https://platform.openai.com/docs/guides/structured-outputs/introduction'
              className='text-primary underline'
              target='_blank'
              rel='noreferrer'
            >
              Strict:
            </a>
          </p>
          <input
            {...register('strict')}
            type='checkbox'
            className='checkbox-dark w-5 h-5'
            disabled={!props.editing}
          />
        </div>
        <div className='flex items-center gap-4'>
          <p className='text-sms font-bold'>
            <a
              href='https://platform.openai.com/docs/guides/function-calling/configuring-function-calling-behavior-using-the-tool_choice-parameter'
              className='text-primary underline'
              target='_blank'
              rel='noreferrer'
            >
              Force Call:
            </a>
          </p>
          <input
            {...register('required')}
            type='checkbox'
            className='checkbox-dark w-5 h-5'
            disabled={!props.editing}
          />
          <ErrorMessage
            errors={errors}
            name='required'
            render={({ message }) => (
              <div className='text-sms text-red-002 mt-1'>{message}</div>
            )}
          />
        </div>
      </div>
      <div>
        <p className='text-sms mb-1 font-bold'>Parameters (JSON):</p>
        <textarea
          className={`flex h-76 py-2 px-2.5 resize-none mb-0 scrollbar ${
            errors.parameters ? 'field-error' : 'field'
          }`}
          disabled={!props.editing}
          {...register('parameters', {
            required: true,
          })}
        />
        <ErrorMessage
          errors={errors}
          name='parameters'
          render={({ message }) => (
            <div className='text-sms text-red-002 mt-1'>{message}</div>
          )}
        />
      </div>
      <div className='flex items-center justify-center gap-4'>
        {props.editing ? (
          <>
            <button
              type='button'
              className='btn-secondary w-40 h-10'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-40 h-10'
              disabled={!props.editing}
            >
              Save
            </button>
          </>
        ) : (
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={props.onCancel}
          >
            Close
          </button>
        )}
      </div>
    </form>
  );
}

function FuntionListEditor(props: { editing: boolean }) {
  const { control } = useFormContext<FormData>();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    fields: functions,
    append,
    update,
    remove,
  } = useFieldArray<FormData, 'functions', 'key'>({
    control: control,
    name: 'functions',
    keyName: 'key',
  });

  const onAdd = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-200'
          innerClassName='p-4'
          borderStyle='gray'
        >
          <FunctionEditor
            functions={functions}
            initial={null}
            editing
            onCancel={p.internalOnCancel}
            onSave={(fn) => {
              append(fn);
              p.internalOnConfirm();
            }}
          />
        </ModalWrapper>
      ),
    });
  };

  const onEdit = async (fn: FunctionDefinition, index: number) => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-200'
          innerClassName='p-4'
          borderStyle='gray'
        >
          <FunctionEditor
            functions={functions}
            initial={fn}
            editing={props.editing}
            onCancel={p.internalOnCancel}
            onSave={(fn) => {
              update(index, fn);
              p.internalOnConfirm();
            }}
          />
        </ModalWrapper>
      ),
    });
  };

  const onRemove = async (index: number) => {
    remove(index);
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-sms mb-1 flex items-center gap-10'>
        <p className='font-bold'>Functions:</p>
        <button
          type='button'
          className='btn text-primary underline'
          disabled={!props.editing}
          onClick={onAdd}
        >
          +Add
        </button>
      </div>
      <ul className='flex flex-col gap-3'>
        {functions.map((fn, idx) => (
          <li
            key={fn.id}
            className='flex items-center justify-between text-sms group'
          >
            <button
              type='button'
              className='underline'
              onClick={() => onEdit(fn, idx)}
            >
              {fn.name}
            </button>
            {props.editing && (
              <button
                type='button'
                className='text-red-002 hidden group-hover:block'
                onClick={() => onRemove(idx)}
              >
                <DeleteIcon />
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

function VariableEditor(props: {
  variables: ModelsVariableDefinition[];
  initial: ModelsVariableDefinition | null;
  onCancel: () => void;
  onSave: (v: ModelsVariableDefinition) => void;
  editing: boolean;
}) {
  const { variables } = props;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ModelsVariableDefinition>({
    defaultValues: {
      ...props.initial,
      id: props.initial?.id ?? uuid4(),
    },
  });

  function submit(data: ModelsVariableDefinition) {
    if (!props.editing) return;
    if (variables.some((v) => v.id !== data.id && v.name === data.name)) {
      setError('name', {
        type: 'custom',
        message: 'Variable names must be unique.',
      });
      return;
    }
    props.onSave(data);
  }

  return (
    <form
      className='w-full flex flex-col gap-4 text-white'
      onSubmit={handleSubmit((data) => {
        submit(data);
      })}
    >
      <div className='text-xl text-center'>{`${
        props.editing ? (!props.initial ? 'Add' : 'Update') : 'View'
      } Variable Definition`}</div>
      <div>
        <p className='text-sms mb-1 font-bold'>Name:</p>
        <input
          {...register('name', { required: true })}
          className={`${errors.name ? 'field-error' : 'field'} mb-0`}
          disabled={!props.editing}
        />
        <ErrorMessage
          errors={errors}
          name='name'
          render={({ message }) => (
            <div className='text-sms text-red-002'>{message}</div>
          )}
        />
      </div>
      <div>
        <p className='text-sms mb-1 font-bold'>Description:</p>
        <input
          {...register('description', { required: true })}
          className={`${errors.description ? 'field-error' : 'field'} mb-0`}
          disabled={!props.editing}
        />
        <ErrorMessage
          errors={errors}
          name='description'
          render={({ message }) => (
            <div className='text-sms text-red-002'>{message}</div>
          )}
        />
      </div>
      <div className='flex items-center gap-4'>
        <div className='flex items-center gap-4'>
          <p className='text-sms font-bold'>Required:</p>
          <input
            {...register('required')}
            type='checkbox'
            className='checkbox-dark w-5 h-5'
            disabled={!props.editing}
          />
          <ErrorMessage
            errors={errors}
            name='required'
            render={({ message }) => (
              <div className='text-sms text-red-002 mt-1'>{message}</div>
            )}
          />
        </div>
      </div>
      <div className='flex items-center justify-center gap-4'>
        {props.editing ? (
          <>
            <button
              type='button'
              className='btn-secondary w-40 h-10'
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-40 h-10'
              disabled={!props.editing}
            >
              Save
            </button>
          </>
        ) : (
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={props.onCancel}
          >
            Close
          </button>
        )}
      </div>
    </form>
  );
}

function VariableListEditor(props: { editing: boolean }) {
  const { control } = useFormContext<FormData>();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    fields: variables,
    append,
    update,
    remove,
  } = useFieldArray<FormData, 'variables', 'key'>({
    control: control,
    name: 'variables',
    keyName: 'key',
  });

  const onAdd = async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-160'
          innerClassName='p-4'
          borderStyle='gray'
        >
          <VariableEditor
            variables={variables}
            initial={null}
            editing
            onCancel={p.internalOnCancel}
            onSave={(v) => {
              append(v);
              p.internalOnConfirm();
            }}
          />
        </ModalWrapper>
      ),
    });
  };

  const onEdit = async (v: ModelsVariableDefinition, index: number) => {
    await triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-160'
          innerClassName='p-4'
          borderStyle='gray'
        >
          <VariableEditor
            variables={variables}
            initial={v}
            editing={props.editing}
            onCancel={p.internalOnCancel}
            onSave={(v) => {
              update(index, v);
              p.internalOnConfirm();
            }}
          />
        </ModalWrapper>
      ),
    });
  };

  const onRemove = async (index: number) => {
    remove(index);
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-sms mb-1 flex items-center gap-10'>
        <p className='font-bold'>Variables:</p>
        <button
          type='button'
          className='btn text-primary underline'
          disabled={!props.editing}
          onClick={onAdd}
        >
          +Add
        </button>
      </div>
      <ul className='flex flex-col gap-3'>
        {variables.map((v, idx) => (
          <li
            key={v.name}
            className='flex items-center justify-between text-sms group'
          >
            <button
              type='button'
              className='underline'
              onClick={() => onEdit(v, idx)}
            >
              {v.name}
            </button>
            {props.editing && (
              <button
                type='button'
                className='text-red-002 hidden group-hover:block'
                onClick={() => onRemove(idx)}
              >
                <DeleteIcon />
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export function ModelPicker(props: {
  vendor: EnumsAIVendor;
  modelId: string;
  onChange: (modelId: string) => void;
  isDisabled?: boolean;
}): JSX.Element {
  const styles = useMemo(() => buildReactSelectStyles(), []);
  const { data } = useSWR(['/ai/models', props.vendor], async () => {
    const resp = await apiService.aiGeneral.listModels(props.vendor);
    return resp.data;
  });

  const value = data?.models?.find((m) => m.id === props.modelId);
  const defaultValue = data?.models.find((m) => m.id === data.defaultModelId);
  const onChange = useLiveCallback(props.onChange);

  useEffect(() => {
    if (props.modelId || !data?.defaultModelId) return;
    onChange(data.defaultModelId);
  }, [data?.defaultModelId, onChange, props.modelId]);

  const handleSingleChange = (option: SingleValue<DtoAIModel>) => {
    if (option) {
      onChange(option.id);
    }
  };

  return (
    <Select<DtoAIModel, false>
      placeholder={`Select model`}
      styles={styles}
      options={data?.models ?? []}
      value={value ?? defaultValue}
      classNamePrefix='select-box-v2'
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No model matched';
      }}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      onChange={handleSingleChange}
      isDisabled={props.isDisabled}
    />
  );
}

export function PromptTemplateEditor(props: {
  template: Nullable<DtoPromptTemplate>;
  mutate?: KeyedMutator<DtoPromptTemplate | null>;
  initialEditing: boolean;
}): JSX.Element {
  const { template, mutate } = props;
  const [editing, setEditing] = useState(props.initialEditing);
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const navigate = useNavigate();
  const styles = useMemo(() => buildReactSelectStyles(), []);
  const form = useForm<FormData>({
    defaultValues: {
      ...template,
      vendor: EnumsAIVendor.AIVendorOpenai,
      type: template?.type ?? EnumsPromptTemplateType.PromptTemplateTypeSimple,
      functions: template?.functions ?? [],
      variables: template?.variables ?? [],
      settings: template?.settings ?? {
        fileSearchEnhancement: false,
      },
    },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset: resetForm,
  } = form;

  const {
    state: { state, error },
    call: submit,
    reset,
  } = useLiveAsyncCall(async (data: FormData) => {
    reset();
    if (template?.id) {
      await apiService.promptTemplate.updateTemplate(template.id, data);
    } else {
      await apiService.promptTemplate.createTemplate(data);
    }
    mutate?.();
    navigate('..');
  });

  const unlock = async () => {
    if (!sessionStorage.getItem('prompt-template-edit-unlock')) {
      const response = await confirmCancel({
        kind: 'confirm-cancel',
        prompt: (
          <div className='text-white text-base text-center'>
            <div className='flex flex-col gap-2'>
              <div className='text-2xl font-medium text-tertiary'>WARNING</div>
              <ul className='text-sm list-disc list-inside text-left flex flex-col gap-2'>
                <li>
                  You can fine-tune the GPT role or rules in the system prompt.
                </li>
                <li>
                  The response format needs to be aligned with our code
                  handling, otherwise the game will be broken. Please don't mess
                  them up.
                </li>
                <li>For safety, please test the game after making changes.</li>
              </ul>
            </div>
          </div>
        ),
        confirmBtnLabel: 'OKAY',
        confirmBtnVariant: 'primary',
        confirmOnly: true,
      });
      sessionStorage.setItem('prompt-template-edit-unlock', 'true');
      if (response.result !== 'confirmed') return;
    }

    setEditing(true);
  };

  const editable = PromptTemplateUtils.Editable(template);

  const vendor = form.watch('vendor');

  return (
    <FormProvider {...form}>
      <form
        className='flex flex-col gap-4 text-white'
        onSubmit={handleSubmit((data) => {
          submit(data);
        })}
      >
        <div className='flex gap-4'>
          <div className='w-2/3 flex flex-col gap-4'>
            <div>
              <p className='text-sms mb-1 font-bold'>Name:</p>
              <input
                {...register('name')}
                className='field mb-0'
                disabled={!editing}
              />
            </div>
            <div className='flex items-center gap-4'>
              <div className='w-1/2'>
                <p className='text-sms mb-1 font-bold'>Vendor:</p>
                <Controller<FormData, 'vendor'>
                  control={control}
                  name='vendor'
                  render={({ field }) => (
                    <Select<Option<DtoPromptTemplate['vendor']>>
                      classNamePrefix='select-box-v2'
                      styles={styles}
                      value={{ label: field.value, value: field.value }}
                      onChange={(e) => {
                        if (e?.value) field.onChange(e.value);
                      }}
                      options={vendorOptions}
                      isSearchable={false}
                      isDisabled={!editing}
                      isClearable={false}
                    />
                  )}
                />
              </div>
              <div className='w-1/2'>
                <p className='text-sms mb-1 font-bold'>Model:</p>
                <Controller<FormData, 'vendorModelId'>
                  control={control}
                  name='vendorModelId'
                  render={({ field }) => (
                    <ModelPicker
                      modelId={field.value}
                      vendor={vendor}
                      onChange={field.onChange}
                      isDisabled={!editing}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <p className='text-sms mb-1 font-bold'>
                Type: (unchangeable after creation)
              </p>
              <Controller<FormData, 'type'>
                control={control}
                name='type'
                render={({ field }) => (
                  <>
                    <Select<Option<DtoPromptTemplate['type']>>
                      classNamePrefix='select-box-v2'
                      styles={styles}
                      value={{ label: field.value, value: field.value }}
                      onChange={(e) => {
                        if (e?.value) field.onChange(e.value);
                      }}
                      options={typeOptions}
                      isSearchable={false}
                      isDisabled={!editing || !!template?.id}
                    />
                    {!template?.id && (
                      <p className='text-xs mt-1'>
                        {PromptTemplateUtils.HintForType(field.value)}
                      </p>
                    )}
                  </>
                )}
              />
            </div>
            <div>
              <p className='text-sms mb-1 font-bold'>System Prompt:</p>
              <textarea
                {...register('systemPrompt')}
                className='field h-80 py-2 scrollbar'
                disabled={!editing}
              />
            </div>
          </div>
          <div className='w-1/3 flex flex-col gap-10'>
            <label className='flex items-center gap-4'>
              <p className='text-sms mb-1 font-bold'>
                File Search Enhancement (2-pass)
              </p>
              <Controller<FormData, 'settings.fileSearchEnhancement'>
                control={control}
                name='settings.fileSearchEnhancement'
                render={({ field }) => (
                  <input
                    type='checkbox'
                    className='checkbox-dark w-5 h-5'
                    disabled={!editing}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </label>
            <FuntionListEditor editing={editing} />
            <VariableListEditor editing={editing} />
          </div>
        </div>
        {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
        {editable ? (
          <div className='flex items-center justify-start gap-2'>
            {!editing ? (
              <button
                className='btn-warning w-40 h-10 flex items-center justify-center'
                onClick={() => unlock()}
                type='button'
              >
                Unlock
              </button>
            ) : (
              <button
                className='btn-secondary w-40 h-10 flex items-center justify-center'
                onClick={() => {
                  resetForm();
                  setEditing(false);
                }}
                disabled={state.isRunning || isSubmitting}
                type='button'
              >
                Cancel
              </button>
            )}
            {editing && (
              <button
                type='submit'
                className='btn-primary w-40 h-10 flex items-center justify-center'
                disabled={state.isRunning || isSubmitting || !editing}
              >
                {state.isRunning || isSubmitting ? <Loading text='' /> : 'Save'}
              </button>
            )}
          </div>
        ) : (
          <div className='text-tertiary'>
            The template owned by System is readonly.
          </div>
        )}
      </form>
    </FormProvider>
  );
}
